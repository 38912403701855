
import {
  mortgageMapActions,
  mortgageMapGetters,
  mortgageMapMutations,
  mortgageMapState
} from "@/store/modules/mortgage";
import { mortgageListViewTableColumns } from "./mortgageListViewTableColumns";

import Vue from "vue";
export default Vue.extend({
  name: "mortgage-list-view",
  props: {
    showOther: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    ...mortgageMapActions(["getMortgages"]),
    ...mortgageMapMutations({
      addMortgageOtherOption: "ADD_MORTGAGE_OTHER"
    }),
    async fetchPageData(queryObject: any): Promise<void> {
      const { __query } = queryObject;
      let showOther = __query && Object.keys(__query);
      try {
        this.$emit("scrollTop");
        await this.getMortgages({
          query: { ...queryObject, __sort: "mortgageName" }
        });

        if (showOther && !this.mortgages.length) {
          this.addMortgageOtherOption({});
        }
      } catch (error) {
        this.$appNotifyError("Error Fetching MortgageList");
        this.$bugSnagClient.notify(error);
      }
    }
  },
  data() {
    return {
      mortgageListViewTableColumns
    };
  },
  computed: {
    ...mortgageMapState(["mortgages", "makingApiRequest", "links"]),
    ...mortgageMapGetters(["getTotalRecords"])
  }
});


/**
This allows user to browse and select morgage to be added
selected mortage is sent back to parent in a success event
 */
import { mortgageMapState } from "@/store/modules/mortgage";
import Vue from "vue";
import MortgageListView from "../MortgageListView/MortgageListView.vue";

export default Vue.extend({
  components: { MortgageListView },
  name: "form-builder-data-collector",
  props: {
    title: {
      type: String,
      required: false,
      default: "Form Filler"
    },
    showOther: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      validationData: {}
    };
  },
  computed: {
    ...mortgageMapState(["makingApiRequest"])
  }
});

import mortgageToolTip from "./mortgageToolTip.vue";
export const mortgageListViewTableColumns = {
  mortgageName: {
    formatDataKey: "Name",
    formatDataValue: (arg: string) => arg,
    formatDataComponent: mortgageToolTip,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  address: {
    formatDataKey: "Address",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  city: {
    formatDataKey: "City",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    minWidth: "100px"
  },
  state: {
    formatDataKey: "State",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    minWidth: "90px"
  },
  zipCode: {
    formatDataKey: "Zip Code",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    minWidth: "100px"
  }
};

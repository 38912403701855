
import { getPathAPI } from "@/helpers";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import {
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import { cloneDeep } from "lodash";
import Vue from "vue";
interface IUpload {
  error: string;
  showModal: boolean;
  fetchingFile: boolean;
  fileList: any[];
  showImage: boolean;
  innerVisible: boolean;
  accessToken: any;
  attachmentID: any;
  key: any;
  image: string;
  loadingText: string;
  fileIsStaticBlob: boolean;
  tempFileList: any[];
}
export default Vue.extend({
  name: "ChangeRequestFileUpload",
  created() {},
  props: {
    formType: {
      required: true,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    attachments: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    isChangeRequest: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data(): IUpload {
    return {
      error: "",
      showModal: false,
      fetchingFile: false,
      fileList: [],
      showImage: false,
      innerVisible: false,
      accessToken: localStorage.getItem("access"),
      attachmentID: "",
      key: "",
      image: "",
      loadingText: "Uploading...",
      fileIsStaticBlob: false,
      tempFileList: []
    };
  },
  computed: {
    ...propertyChangeRequestMapState(["editing", "makingApiRequest"]),
    ...propertyChangeRequestMapGetters([
      "getPropertyChangeRequestsFromStore",
      "getEditingPropertyChangeRequest"
    ])
  },
  destroyed() {
    this.tempFileList = [];
  },
  methods: {
    ...atlasfileMapActions(["deleteAtlasFile"]),
    url() {
      return getPathAPI();
    },
    imageAttachmentURL(attachment: any) {
      if (attachment) {
        if (attachment._id) {
          this.fileIsStaticBlob = false;
          return `${this.url()}/file/view/${attachment._id}?accessToken=${
            this.accessToken
          }`;
        }
        if (typeof attachment === "object" && !Array.isArray(attachment)) {
          const blobUrl = URL.createObjectURL(attachment);
          this.fileIsStaticBlob = true;
          return blobUrl;
        }
      }
    },
    fileExtension(attachment: any) {
      if (attachment.ext) return attachment.ext;
      if (attachment?.type) {
        const [, extension] = attachment.type.split("/");
        return extension;
      }
      return null;
    },
    openDeleteDialog(attachmentID: any, image: any, key: any) {
      this.attachmentID = attachmentID;
      this.key = key;
      this.image = image;
      this.innerVisible = true;
      this.$modal.show("attachmentDeleteModal");
    },
    cancelDeleteImage() {
      this.innerVisible = false;
    },
    async deleteUploadedFile(): Promise<void> {
      try {
        let newAttachment = cloneDeep(this.attachments);
        if (this.attachmentID) {
          await this.deleteAtlasFile(this.attachmentID);
        }
        newAttachment.splice(this.key, 1);
        this.tempFileList.splice(this.key, 1);

        this.innerVisible = false;
        this.showImage = false;
        this.$appNotifySuccess("Document deleted successfully");
        this.$modal.hide("attachmentDeleteModal");
        this.$emit("delete-attachment", newAttachment);
        this.$appNotifySuccess("Document deleted successfully");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    handleFileChange(file: any) {
      const fileAlreadyExists = this.tempFileList.find(
        (innerFile: File) =>
          innerFile?.name.toLowerCase() === file?.name.toLowerCase()
      );
      if (!fileAlreadyExists) {
        this.tempFileList.push(file.raw);
        this.$emit("upload-success", [file.raw]);
      }
      this.fileList = [];
    },
    handleRemove(file: any): void {
      this.fileList.splice(
        this.fileList.map((file: any) => file.uid).indexOf(file.uid),
        1
      );
    },
    closeModal() {
      this.showModal = false;
    },
    handleClose() {
      this.showImage = false;
    },

    viewAlreadyUploadedFile() {
      if (!this.attachments.length) {
        this.$appNotifyError("No Files Found");
        return;
      }
      this.showImage = true;
    },

    onFileUploadSuccess(result: any) {
      this.$appNotifySuccess("File Upload Success");
      this.$emit("upload-success", result);
      this.closeModal();
    },
    onFileUploadError(err: string) {
      this.error = err;
    }
  }
});


import Vue from "vue";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
import { stringifyAddress } from "@/helpers/stringifyAddress";
export default Vue.extend({
  name: "mortgageToolTip",
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    processName(): string {
      return capitalizeFirstLetter(this.row.mortgageName) || "N/A";
    },
    toolTipContent(): string {
      const mortgageName = `Name: ${this.processName || "N/A"}`;
      const mortgageAddress = `Address: ${this.row.address}, ${stringifyAddress(
        this.row
      )}`;
      return capitalizeFirstLetter(`${mortgageName}  |  ${mortgageAddress}`);
    }
  }
});
